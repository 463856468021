<template>
  <v-container>
    <core-multi-lang isNormal />
    <v-layout justify-center wrap>
      <vue-qrcode
          v-if="imgUrl"
          style="width: 100%"
          :value="imgUrl"
      />
    </v-layout>
  </v-container>
</template>

<script>
import VueQrcode from "vue-qrcode";
export default {
  name: "WebViewQr",
  components:{
    VueQrcode
  },
  data() {
    return {
      imgUrl:''
    }
  },
  watch:{
    imgUrl() {
      this.imgUrl = this.$route.query.qr
    }
  },
  mounted() {
    this.imgUrl = this.$route.query.qr
  }
}
</script>

<style scoped>
</style>
